var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.$attrs.name,"slim":true,"rules":_vm.rules}},[_c('OField',{attrs:{"label":_vm.label || _vm.$attrs.placeholder,"label-for":_vm.uid,"label-class":!_vm.label && _vm.$attrs.placeholder ? 'sr-only' : '',"variant":_vm.errors && _vm.errors[_vm.$attrs.name] ? 'danger' : '',"message":_vm.errors && _vm.errors[_vm.$attrs.name] && _vm.errors[_vm.$attrs.name][0],"root-class":_vm.fieldClasses,"data-name":_vm.$attrs.name}},[_c('ODropdown',{ref:"Dropdown",attrs:{"id":_vm.uid,"value":_vm.value,"scrollable":_vm.isScrollable,"max-height":_vm.maxHeight,"append-to-body":_vm.appendToBody,"expanded":_vm.appendToBody,"required":`${_vm.rules.includes('required')}`,"disabled":_vm.disabled || !_vm.options.length,"aria-disabled":_vm.disabled || !_vm.options.length,"root-class":_vm.rootClass},on:{"input":function($event){return _vm.$emit('select', $event)},"change":function($event){return _vm.$emit('change', $event)},"active-change":_vm.onActiveChange},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return _c('button',{ref:"Button",class:[
          _vm.classes,
          'ucsc-input',
          {
            'ucsc-input--no-border': !_vm.border && !active,
            'ucsc-input--focused': active,
            'ucsc-input--focus': active,
            'ucsc-input--placeholder': !_vm.value,
            'ucsc-input--danger':
              _vm.errors && _vm.errors[_vm.$attrs.name] && _vm.errors[_vm.$attrs.name][0],
            'cursor-not-allowed': !_vm.options.length,
          },
        ],attrs:{"type":"button","tabindex":"0","role":"combobox","aria-label":_vm.ariaLabel || _vm.label || _vm.$attrs.placeholder,"readonly":_vm.disabled || !_vm.options.length,"aria-disabled":_vm.disabled || !_vm.options.length,"aria-activedescendant":`${_vm.uid}-${_vm.indexFocused}`,"aria-expanded":`${active}`},on:{"blur":_vm.onBlur,"focus":function($event){return _vm.$emit('focus')},"keydown":_vm.onKeydownButton}},[_c('span',{staticClass:"ucsc-input__inner"},[_vm._v("\n          "+_vm._s(_vm.value ? _vm.value[_vm.field] : _vm.$attrs.placeholder)+"\n        ")]),_vm._v(" "),(_vm.showOptionsLength)?_c('span',{staticClass:"o-drop__length"},[_vm._v("\n          ("+_vm._s(_vm.options.length)+")\n        ")]):_vm._e(),_vm._v(" "),_c('SvgIcon',{staticClass:"ml-2 o-drop__trigger-icon",attrs:{"name":active ? 'angle-up-blue' : 'angle-down'}})],1)}}])},[_vm._v(" "),_c('ClientOnly',[(_vm.showPlaceholderOption && _vm.$attrs.placeholder)?_c('ODropdownItem',{staticClass:"ucsc-input--placeholder",attrs:{"value":null,"tabindex":"-1","role":"option","aria-selected":_vm.value === null}},[_c('span',[_vm._v(_vm._s(_vm.$attrs.placeholder))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function(item,index){return _c('ODropdownItem',{key:index,attrs:{"id":`${_vm.uid}-${index}`,"value":item,"role":"option","tabindex":_vm.indexFocused === index ? '0' : '-1',"aria-selected":_vm.$options.isEqual(_vm.value, item)},nativeOn:{"keydown":function($event){return _vm.onKeydown($event, item, index)}}},[_c('span',[_vm._v(_vm._s(item[_vm.field]))]),_vm._v(" "),_c('span',{staticClass:"o-drop__item-radio"})])})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }